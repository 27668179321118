.ham {
    padding: 5px 18px 5px 5px;
}

.ham__div {
    width: 30px;
    height: 3px;
    background-color: rgb(255, 255, 255);
    margin: 6px 0;
}

.ham__div1 {
    width: 30px;
    height: 3px;
    background-color: rgb(255, 255, 255);
    margin: 6px 0;
    -webkit-animation: ham1 0.5s linear;
    animation: ham1 0.5s linear;
    animation-fill-mode: both;
}

@-webkit-keyframes ham1 {
    from {-webkit-transform: rotate(0deg);}
    to {-webkit-transform: rotate(45deg) translate(5px, 5px);}
}

@keyframes ham1 {
    from {-webkit-transform: rotate(0deg);}
    to {-webkit-transform: rotate(45deg) translate(5px, 5px);}
}

.ham__div2 {
    width: 30px;
    height: 3px;
    background-color: rgb(255, 255, 255);
    margin: 6px 0;
    -webkit-animation: ham2 0.5s linear;
    animation: ham2 0.5s linear;
    animation-fill-mode: both;
}

@-webkit-keyframes ham2 {
    from {opacity:1;}
    to {opacity:0;}
}

@keyframes ham2 {
    from {opacity:1;}
    to {opacity:0;}
}

.ham__div3 {
    width: 30px;
    height: 3px;
    background-color: rgb(255, 255, 255);
    margin: 6px 0;
    -webkit-animation: ham3 0.5s linear;
    animation: ham3 0.5s linear;
    animation-fill-mode: both;
}

@-webkit-keyframes ham3 {
    from {-webkit-transform: rotate(0deg);}
    to {-webkit-transform: rotate(-45deg) translate(8px, -8px);}
}

@keyframes ham3 {
    from {-webkit-transform: rotate(0deg);}
    to {-webkit-transform: rotate(-45deg) translate(8px, -8px);}
}

.ham__div11 {
    width: 30px;
    height: 3px;
    background-color: rgb(255, 255, 255);
    margin: 6px 0;
    -webkit-animation: ham11 0.5s linear;
    animation: ham11 0.5s linear;
    animation-fill-mode: both;
}

@-webkit-keyframes ham11 {
    from {-webkit-transform: rotate(45deg) translate(5px, 5px);}
    to {-webkit-transform: rotate(0deg) translate(0px, 0px);}
}

@keyframes ham11 {
    from {-webkit-transform: rotate(45deg) translate(5px, 5px);}
    to {-webkit-transform: rotate(0deg) translate(0px, 0px);}
}

.ham__div22 {
    width: 30px;
    height: 3px;
    background-color: rgb(255, 255, 255);
    margin: 6px 0;
    -webkit-animation: ham22 0.5s linear;
    animation: ham22 0.5s linear;
    animation-fill-mode: both;
}

@-webkit-keyframes ham22 {
    from {opacity:0;}
    to {opacity:1;}
}

@keyframes ham22 {
    from {opacity:0;}
    to {opacity:1;}
}

.ham__div33 {
    width: 30px;
    height: 3px;
    background-color: rgb(255, 255, 255);
    margin: 6px 0;
    -webkit-animation: ham33 0.5s linear;
    animation: ham33 0.5s linear;
    animation-fill-mode: both;
}

@-webkit-keyframes ham33 {
    from {-webkit-transform: rotate(-45deg) translate(8px, -8px);}
    to {-webkit-transform: rotate(0deg) translate(0px, 0px);}
}

@keyframes ham33 {
    from {-webkit-transform: rotate(-45deg) translate(8px, -8px);;}
    to {-webkit-transform: rotate(0deg) translate(0px, 0px);}
}

.ham__nav {
    background: #364051;
    display: flex;
    flex-direction: column;
    -webkit-animation-name: navigation; 
    -webkit-animation-duration: 0.5s; 
    animation-name: navigation;
    animation-duration: 0.5s;
}

.ham__nav-temp {
    background: #364051;
    display: flex;
    flex-direction: column;
}

@-webkit-keyframes navigation {
    from {width:0;height:0;opacity: 0;}
    to {width: 100vw;height:751px;opacity: 1;}
}

@keyframes navigation {
    from {width: 0;height:0;opacity: 0;}
    to {width: 100vw;height:751px;opacity: 1;}
}

.ham__nav1 {
    background: #364051;
    display: flex;
    flex-direction: column;
    -webkit-animation-name: navigation1;
    -webkit-animation-duration: 0.5s;
    animation-name: navigation1;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(.5,.69,.63,.88);
}

@-webkit-keyframes navigation1 {
    from {width: 100vw;height:751px;opacity: 1;}
    to {width: 0;height:0;opacity: 0;}
}

@keyframes navigation1 {
    from {width: 100vw;height:751px;opacity: 1;}
    to {width: 0;height:0;opacity: 0;}
}

.ham__nav-pic {
    background: #364051;
    display: flex;
    flex-direction: column;
    -webkit-animation-name: navigation-pic;
    -webkit-animation-duration: 0.5s;
    animation-name: navigation-pic;
    animation-duration: 0.5s;
    animation-timing-function: cubic-bezier(.5,.69,.63,.88);
}

@-webkit-keyframes navigation-pic {
    from {width:100vw;height:0;opacity: 0;}
    to {width: 100vw;height:220px;opacity: 1;}
}

@keyframes navigation-pic {
    from {width: 100vw;height:0;opacity: 0;}
    to {width: 100vw;height:220px;opacity: 1;}
}

.ham__nav1-pic {
    background: #364051;
    display: flex;
    flex-direction: column;
    -webkit-animation-name: navigation1-pic;
    -webkit-animation-duration: 0.5s;
    animation-name: navigation1-pic;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes navigation1-pic {
    from {width: 100vw;height:220px;opacity: 1;}
    to {width: 100vw;height:0;opacity: 0;}
}

@keyframes navigation1-pic {
    from {width: 100vw;height:220px;opacity: 1;}
    to {width: 100vw;height:0;opacity: 0;}
}