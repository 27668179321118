.text-input {
    border: 1px solid #cacccd;
    height: 50px;
    font-size: 1.8rem;
    font-weight: 300;
    padding: 1.2rem;
  }
  
  .select {
    @extend .text-input;
  }
  
  .textarea {
    @extend .text-input;
    height: 10rem;
  }
  