/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
 */

* {
  box-sizing: border-box;
  /* height: 100vh;
  width: 100vw; */
}

html {
  font-size: 62.5%;
}

body {
  color: #333;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.6rem;
  line-height: 1.6;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

.is-active {
  font-weight: bold;
}

a:link {
  text-decoration: none;
}

.box-layout {
  background: white;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: flex-start;
  width: 100vw;
}

.box-layout-bg{
  background-size: cover;
  justify-content: flex-start;
  width: 100vw;
}

.box-layout-projects {
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: flex-start;
  width: 100vw;
}

.box-layout__box {
  background: white;
  text-align: center;
  width: 50rem;
}

.box-layout-projects__box {
  background-size: cover;
  display: flex;
  flex-direction: column;
  padding: 3.2rem 1.6rem;
  text-decoration: none;
  text-align: center;
  background-size: cover;
  margin: 10px;
  transition: background .3s ease;

  @media (min-width: 45rem) {
    align-items: center;
    justify-content: center;
    width: 25rem;
    height: 30rem;
    box-shadow: rgb(167, 167, 167) 2px 2px 4px;
  }
  @media (max-width: 44.09rem) {
    width: 25rem;
    height: 30rem;
    box-shadow: rgb(167, 167, 167) 2px 2px 4px;
  }
}

.box-layout__title {
  margin: 0 0 1.6rem 0;
  line-height: 1;
}

.box-layout__maxwidth{
  padding: 10px;
}

.box-layout__justifycontent{
  display: flex;
  justify-content: center;
}


.box-layout-projects__box-text {
  display: none;
}

.box-layout-projects__box:hover .box-layout__box-text{
  display: block;
}

.box-layout-projects__box:hover{
  background: #60c07d;
}

.box-layout-projects__box-text>p {
  font-family: 'Courier New', Courier, monospace;
  color: #f5f1f6;
}

.box-layout-projects__box-text>h1 {
  font-family: 'Courier New', Courier, monospace;
  color: black;
}

.box-layout-projects__box-text>h2 {
  font-family: 'Courier New', Courier, monospace;
  color: #e4e0e6;
}


.box-layout-projects__title {
  font-family: 'Courier New', Courier, monospace;
  font-size: 6rem;
  margin: 3.2rem 0 3.2rem 0;
  color: black;
  text-shadow: rgb(167, 167, 167) 2px 2px 4px;
  text-align: center;
  font-weight: 500;
  @media (max-width: 44.09rem) {
    margin-bottom: 0;
  }
}

.box-layout-projects__h3 {
  font-family: 'Courier New', Courier, monospace;
  font-size: 1.6rem;
  color: black;
  text-shadow: rgb(167, 167, 167) 2px 2px 4px;
  text-align: center;
  font-weight: 500;
  @media (max-width: 44.09rem) {
    margin-bottom: 0;
  }
}

.ReactModalPortal>div {
  opacity: 0;
}

.ReactModalPortal .ReactModal__Overlay {
  align-items: center;
  display: flex;
  justify-content: center;
  transition: opacity 200ms ease-in-out;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay--before-close {
  opacity: 0;
}

.modal {
  background: white;
  outline: none;
  text-align: left;
  max-width: 80rem;
  border: #364051 3px solid;
  border-radius: 1.2rem;
  margin: 1.2rem;
  max-height: 100vh;
  overflow-y: auto;
}

.modal-new {
  @media (min-width: 45rem) {
      max-width: 50%;
  }
  @media (max-width: 44.09rem) {
      max-width: 35rem;
    }
  outline: none;
  padding: 1.2rem;
  text-align: center; 
  display: flex;
  justify-content: center;
}

.modal__title {
  background-color: #364051;
  margin: 0;
  padding: 1.2rem;
  color: #84d0d0;
  border-radius: 0.8rem 0.8rem 0 0;
}

.modal__body {
  font-size: 1.5rem;
  font-weight: 100;
  margin: 1.2rem; 
}

.modal__footer {
  background-color: #364051;
  margin: 0;
  padding: 1.2rem;
  color: #84d0d0;
  border-radius: 0 0 0.8rem 0.8rem;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}

.modal-page-desktop {
  padding-top: 1.2rem;
}