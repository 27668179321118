.doc-view{
    height: 800px;
    width: 800px;
}

.doc-view__links {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: left;
    margin: 0%;
    margin-left: 10px;  
}