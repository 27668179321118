.placement-content-container {
    padding: 0 1.6rem;
    max-width: 80rem;
  }

.placement-container { 
    margin: 0px;
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    font-family: 'Merriweather', serif;
    justify-content: space-between;
}

.placement-container-pat { 
    margin: 0px;
}

.placement-container__name {
    font-family: 'Montserrat', sans-serif;
    font-size: 3.2rem;
}

.placement-content-margin{
    margin: 50px 0;
}

.placement-container__text {
    padding-right: 1.6rem;
    font-family: 'Montserrat', sans-serif;
}


.placement-content-pic {
    font-family: 'Merriweather', serif;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

.placement-container__tips {
    width: 26.6rem;
    min-width: 25rem;
    margin-top: 50px;
    padding: 0 1.6rem;
    @media (max-width: 45rem) {
        display: flex;
        flex-direction: row;
        width: 100%;
    }
    @media (max-width: 27rem) {
        display: flex;
        flex-direction: column;
        margin: 30px 0 0 0;
    }
}

.placements-dotted {
    @media (max-width: 890px) {
        display: none;
    }
}

.placements-dotted-mobile {
    @media (min-width: 889px) {
        display: none;
    }
}
.placement-container__tips-header {
    margin: 0%;
    background: #84d0d0;
    background-size: auto;
    font-family: 'Montserrat', sans-serif;
    padding: 3.2rem 1.6rem;
}

.placement-container__tips-content {
    padding: 1.6rem;
    background: #f5f1f6;
    font-family: 'Montserrat', sans-serif;
}

.placement-container__rowDisplay {
    display: flex;
    flex-direction: row;
    justify-content: center;
    @media (max-width: 45rem) {
        flex-direction: column;
    }
}

.placement-header {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    padding: 3rem 0;
    font-family: 'Montserrat', sans-serif;
}

.placement-header-border {
    border-bottom: black solid 2px;
}

.placement-header-content {
    display: inline-block;
    color: #000;
    text-decoration: none;
}

.placement-header-content::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #000;
    transition: width .3s;
}

.placement-header-content:hover::after {
    width: 100%;
}

.placements-cards {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
}

.placements-cards div {
    border: #84d0d0 solid 2px;
    border-radius: 5px;
    margin: 5px;
    width: 50%;
    background: #84d0d0;
}