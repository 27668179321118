.button {
    background: #1c88bf;
    border: none;
    color: white;
    display: inline-block;
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 1;
    padding: 1.2rem;
    text-decoration: none;
  }
  
  .button_material {
    background: #1c88bf;
    border: none;
    color: white;
    display: inline-block;
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 1;
    padding: 1.2rem;
    text-decoration: none;
    margin-right: 10px;
  }
  
  .button__share {
    background: #25d366;
  }
  
  .button2 {
    background: #1c88bf;
    border: none;
    color: black;
    display: inline-block;
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 1;
    padding: 1.2rem;
    text-decoration: none;
  }
  
  .button--link {
    background: none;
  }
  
  .button--link-for-matheader {
    background: none;
    font-size: 1.6rem;
    color: #84d0d0;
  }
  
  .button--link2 {
    background: none;
    padding: 5px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 1.4rem;
  }
  
  .right-align {
    cursor: pointer;
    display: flex;
    justify-content: center;
    border-style: solid;
    border-width: 2px;
    border-color: #44BCB8;
  }
  
  .button--link__bl {
    background: white;
    border: none;
    color: black;
    text-decoration: none;
  }
  
  .button--secondary {
    background: #888;
  }
  
  .whatsapp {
    color: #44BCB8;
  }
  
  .button-more-projects {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 15px 40px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    margin: 5px 2px 20px 2px;
    cursor: pointer;
    font-family: 'Courier New', Courier, monospace;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  
  .button-matpap__disp {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #364051;
    border: 2px solid #364051;
  }
  
  .button_review-tags {
    background-color: white;
    color: #364051;
    border: 2px solid #84d0d0;
    border-radius: 25px;
    padding: 0.6rem;
    margin: 0.2rem;
    outline: none;
  }
  
  .button-addCoins {
    background: #A9A9A9;
    font-size: 1.4rem;
    font-weight: 300;
    padding: 4px 10px;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    border-top-right-radius: 9px;
    border-bottom-right-radius: 9px;
  }
  
  .pointer {cursor: pointer;}
  
  .discord-button {
    display: inline;
    height: 2.6rem;
    margin: 0;
    padding: 0;
  }