@media (min-width: 45rem) {
    .show-for-mobile {
        display: none;
    }
}
  
  
@media (max-width: 44.09rem) {
  .show-for-desktop {
    display: none;
  }
}
  
@media (min-width: 45rem) {
  .show-for-tablet {
    display: none;
  }
}
  