.header {
    background: #364051;
    border-bottom: 3px solid #84d0d0;
    @media (max-width: 45rem) {
      border: none;
    }
  }
  
  .header__content {
    font-family: Helvetica, Arial, sans-serif;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  
  .header__title {
    color: white;
    text-decoration: none;
    h1 {
      margin: 0;
    }
  }
  
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #333;
  }
  
  li {
    float: left;
  }
  
  li a, .dropbtn {
    display: inline-block;
    color: rgb(12, 5, 19);
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }
  
  li a:hover{
    background-color: red;
  }
  
  li.dropdown {
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    border-top: 3px solid #84d0d0;
    background-color: #364051;
    min-width: 200px;
    z-index: 1;
    -webkit-animation-name: navigation2;
    -webkit-animation-duration: 0.5s;
    animation-name: navigation2;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(.5,.69,.63,.88);
  }
  
  @-webkit-keyframes navigation2 {
    from {width: 0;height:0;opacity: 0;}
    to {width: 200px;height:auto;opacity: 1;}
  }
  
  @keyframes navigation2 {
    from {width: 0;height:0;opacity: 0;}
    to {width: 200px;height:auto;opacity: 1;}
  }
  
  .dropdown-content a {
    color: rgb(255, 255, 255);
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
  
  .dropdown-content a:hover {
    background-color: rgb(44, 98, 168);
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  /* /////////BLOGS////////////// */
  .dropdown-blog {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content-blog {
    display: none;
    position: absolute;
    border-top: 3px solid #84d0d0;
    background-color: #364051;
    min-width: 300px;
    z-index: 1;
    -webkit-animation-name: navigation3;
    -webkit-animation-duration: 0.5s;
    animation-name: navigation3;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(.5,.69,.63,.88);
  }
  
  @-webkit-keyframes navigation3 {
    from {width: 0;height:0;opacity: 0;}
    to {width: 300px;height:auto;opacity: 1;}
  }
  
  @keyframes navigation3 {
    from {width: 0;height:0;opacity: 0;}
    to {width: 300px;height:auto;opacity: 1;}
  }
  
  .dropdown-content-blog a {
    color: rgb(255, 255, 255);
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
  
  .dropdown-content-blog a:hover {
    background-color: rgb(44, 98, 168);
  }
  
  .dropdown-blog:hover .dropdown-content-blog {
    display: block;
  }
  /* ////////////////////////////// */
  
  /* /////////// */
  .dropbtn-pic {
    display: inline-block;
    color: rgb(12, 5, 19);
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }
  
  .dropdown-pic {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content-pic {
    display: none;
    position: absolute;
    border-top: 3px solid #84d0d0;
    background-color: #364051;
    min-width: 250px;
    left: -200px;
    -webkit-animation-name: navigation2;
    -webkit-animation-duration: 0.5s;
    animation-name: navigation2;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  
  .dropdown-content-pic div {
    color: rgb(255, 255, 255);
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
  
  .dropdown-content-pic div:hover {background-color: rgb(44, 98, 168);}
  
  .dropdown-pic:hover .dropdown-content-pic {display: block;}
  
  .dropdown-pic:hover .dropbtn-pic {background-color: #3e8e41;}
  /* ///////////////// */
  
  .headerLinks-color {
    background: #84d0d0;
  }
  
  .header-button {
    background: #1c88bf;
    border: none;
    color: white;
    display: inline-block;
    margin-top: 15px;
    border: 2px;
    border-radius: 5px 5px 0 0;
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 1;
    padding: 10px 24px 15px 24px;
    text-decoration: none;
  }
  
  .header-button--link {
    background: none;
  }
  
  .header-button--link-u {
    background: #84d0d0;
    color: black;
  }
  
  .header-button--link-u-papers {
    background: white;
    color: black;
  }
  
  .header-button--link-u-papers:hover {
    background: #364051;
    color: white;
  }
  
  .userProfPic {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }