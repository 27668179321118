.page-header {
    background: #f7f7f7;
    margin-bottom: 3.2rem;
    padding: 3.2rem 0;
  }
  
  .page-header__actions {
    margin-top: 1.6rem;
  }
  
  .page-header__title {
    font-weight: 300;
    margin: 0;
    span {
      font-weight: 700;
    }
  }