.box-layout {
    background: white;
    background-size: cover;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: flex-start;
    width: 100vw;
  }
  
  .box-layout-bg{
    background-size: cover;
    justify-content: flex-start;
    width: 100vw;
  }
  
  .box-layout-projects {
    background-size: cover;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: flex-start;
    width: 100vw;
  }
  
  .box-layout__box {
    background: white;
    text-align: center;
    width: 50rem;
  }
  
  .box-layout-projects__box {
    background-size: cover;
    display: flex;
    flex-direction: column;
    padding: 3.2rem 1.6rem;
    text-decoration: none;
    text-align: center;
    background-size: cover;
    margin: 10px;
    transition: background .3s ease;
  
    @media (min-width: 45rem) {
      align-items: center;
      justify-content: center;
      width: 25rem;
      height: 30rem;
      box-shadow: rgb(167, 167, 167) 2px 2px 4px;
    }
    @media (max-width: 44.09rem) {
      width: 25rem;
      height: 30rem;
      box-shadow: rgb(167, 167, 167) 2px 2px 4px;
    }
  }
  
  .box-layout__title {
    margin: 0 0 1.6rem 0;
    line-height: 1;
  }
  
  .box-layout__maxwidth{
    padding: 10px;
  }
  
  .box-layout__justifycontent{
    display: flex;
    justify-content: center;
  }
  
  
  .box-layout-projects__box-text {
    display: none;
  }
  
  .box-layout-projects__box:hover .box-layout__box-text{
    display: block;
  }
  
  .box-layout-projects__box:hover{
    background: #60c07d;
  }
  
  .box-layout-projects__box-text>p {
    font-family: 'Courier New', Courier, monospace;
    color: #f5f1f6;
  }
  
  .box-layout-projects__box-text>h1 {
    font-family: 'Courier New', Courier, monospace;
    color: black;
  }
  
  .box-layout-projects__box-text>h2 {
    font-family: 'Courier New', Courier, monospace;
    color: #e4e0e6;
  }
  
  
  .box-layout-projects__title {
    font-family: 'Courier New', Courier, monospace;
    font-size: 6rem;
    margin: 3.2rem 0 3.2rem 0;
    color: black;
    text-shadow: rgb(167, 167, 167) 2px 2px 4px;
    text-align: center;
    font-weight: 500;
    @media (max-width: 44.09rem) {
      margin-bottom: 0;
    }
  }
  
  .box-layout-projects__h3 {
    font-family: 'Courier New', Courier, monospace;
    font-size: 1.6rem;
    color: black;
    text-shadow: rgb(167, 167, 167) 2px 2px 4px;
    text-align: center;
    font-weight: 500;
    @media (max-width: 44.09rem) {
      margin-bottom: 0;
    }
  }