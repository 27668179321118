.list-header {
    background: #f7f7f7;
    border: 1px solid #e5e5e5;
    color: #666;
    display: flex;
    justify-content: space-between;
    padding: 1.2rem 1.6rem;
  }
  
  .list-body {
    margin-bottom: 1.6rem;
    @media (min-width: 45rem) {
      margin-bottom: 3.2rem;
    }
  }
  
  .list-item {
    border: 1px solid #e5e5e5;
    color: #333;
    font-family: 'Merriweather', serif;
    flex-direction: column;
    padding: 1.2rem;
    text-decoration: none;
    margin: 1.6rem 0;
    box-shadow: none;
    transition: background .3s ease;
    &:hover {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
      background: #f7f7f7;
    }
    @media (min-width: 45rem) {
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      padding: 1.6rem;
    }
  }
  
  .list_papers {
    display: flex;
    flex-direction: column;
  }
  
  .list_papers-code {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1.3rem;
  }
  
  .list_papers-name {
    margin-bottom:1.6rem;
    line-height: 1.5;
  }
  
  .list_papers-slot {
    color: #84d0d0;
  }
  
  .list-item--message {
    align-items: center;
    color: #666;
    justify-content: center;
    padding: 1.6rem;
    &:hover {
      background: none;
    }
  }
  
  .list-item__title {
    margin: 0;
    word-break: break-all;
  }
  
  .list-item__sub-title {
    color: #666;
    font-size: 1.4rem;
  }
  
  .list-item__data {
    margin: 1.2rem 0 0 0;
    @media (min-width: 45rem) {
      margin: 0;
      padding-left: 1.2rem;
    }
  }
  