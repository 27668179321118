.portfolio-box-layout__box {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    text-align: center;
    background-size: cover;
  
    @media (min-width: 500px) {
      align-items: center;
      justify-content: center;
      width: 100wh;
      height: auto;
    }
  }
  
  .portfolio-box-layout__box:hover .box-layout__box-text {
    display: block;
  }
  
  .portfolio-image {
    max-width: 100%;
    max-height: 100%;
  }
  
  .blog-code-bg {
    background-color: #f2f2f2;
    padding: 3px;
  }
  
  .blog-code-bg>p {
    margin-left: 3px;
  }
  
  .circle {
    height: 5px;
    width: 5px;
    background-color: #555;
    border-radius: 50%;
  }
  
  
  .blogs_cont {
    margin-left: 14%;
    margin-right: 14%;
    margin-top: 4.5%;
    margin-bottom: 4.5%;
  }
  
  .box {
    border: 1px solid rgba(132, 208, 208, 1);
    margin-top: 2.2%;
    margin-bottom: 2.2%;
    display: flex;
  
  }
  
  .box:hover {
    box-shadow: 0 2px 4px 0 #0003, 0 3px 10px 0 #0003;
    background: #f7f7f7;
  }
  
  .text_cont {
    margin: 2.5% 5% 2.5% 5%;
  }
  
  .subtitle {
    color: rgba(117, 117, 117, 1);
    font-size: 75%;
  
    margin-top: 2%;
    margin-bottom: 1%;
  
  }
  
  .author_name {
    color: black;
    font-weight: bold;
  }
  
  .rep_image {
    width: 112px;
    height: 50%;
    /* border: 2px solid blue; */
    margin-top: 5%;
    margin-right: 5%;
    margin-left: auto;
  }
  
  @media(max-width:500px) {
    .info {
      display: none;
    }
  
    .text_cont h2 {
      font-size: small;
    }
  
    .subtitle {
      font-size: xx-small;
      width: 173%;
    }
  
    .rep_image {
      text-align: right;
      margin-top: 2%;
      margin-right: 2%;
      transform: scale(.7);
    }
  
    .rep_image img {
      height: 60px;
      width: 60px;
  
    }
  
    /* .blogs_cont {
       margin-left: 8%;
       margin-right: 8%;
    } */
}