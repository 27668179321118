.form {
    display: flex;
    flex-direction: column;
    >* {
      margin-bottom: 1.6rem;
    }
  }
  
  .form__error {
    margin: 0 0 1.6rem 0;
    font-style: italic;
  }
  